import View from 'ln/view/View';
import ModifiableList from 'ln/list/ModifiableList';
import setup from 'ln/setup/setup';
import CheckboxFilter from 'lnui/filter/CheckboxFilter';
import TermFilter from 'lnui/filter/TermFilter';
import Model from 'ln/model/Model';

class Filter extends View {

	private jobFilter:CheckboxFilter;
	private termFilter: TermFilter;
	private areaFilter: CheckboxFilter;

	init() {

		this.jobFilter = new CheckboxFilter( ["jobs"] );
		this.jobFilter.render( this.node.js( 'jobs' ) );
		this.jobFilter.fill( this.getJobs() );

		this.areaFilter = new CheckboxFilter( ["areas"] );
		this.areaFilter.render( this.node.js( 'areas' ) );
		this.areaFilter.fill( this.getAreas() );

		this.termFilter = new TermFilter( [ "title" ] );
		this.termFilter.ignoreCase = true;
		this.termFilter.startSearchAtChar = 1;
		this.termFilter.render( this.node.js( 'term-input' ) );


	}
	registerOn( list:ModifiableList<Model> ) {
		this.jobFilter.registerOn( list );
		this.termFilter.registerOn( list );
		this.areaFilter.registerOn( list );
	}

	getJobs() {
		return setup.data( 'books' ).reduce( ( accumulator, currentValue ) => {
			return accumulator.concat( ( currentValue.jobs ) ? currentValue.jobs : [] );
		}, [] ).filter( function (item, pos, self ) {
			return self.indexOf( item ) == pos
		});
	}
	
	getAreas() {
		return setup.data( 'books' ).reduce( ( accumulator, currentValue ) => {
			return accumulator.concat( ( currentValue.areas ) ? currentValue.areas : [] );
		}, [] ).filter( function (item, pos, self ) {
			return self.indexOf( item ) == pos
		});
	}
}

export default Filter;