import Signal from 'ln/signal/Signal';
import Node from 'ln/node/Node';
import View from 'ln/view/View';
import Window from 'ln/node/Window';
import Element from './elements/Element';
import Interval from 'ln/util/Interval';

/**
 * Interface for all listener function that listen on the top signal
 */
interface ScrollChangeListener {
	( visible:Array<Element>, ...args:any[] );
}

/**
 * A class that monitors the visible elements of a lernbuch
 */
class ScrollMonitor {
	
	// Offest-Top and Offset-Bottom (in case of fixed header / footer)
	public offsetTop:number = 0;
	public offsetBottom:number = 0;
	
	private visibleElements:Array<View> = [];
	private _elements:Array<View> = [];
	
	// event when the top visible element has changed
	public change:Signal<ScrollChangeListener> = new Signal<ScrollChangeListener>();
	
	constructor( elements:Array<View> = [] ) {
		
		this._elements = elements;

		// register on window events
		Window.resize.add( this.update, this );
		Window.scroll.add( this.update, this );

	}

	/**
	 * Set the elements for the scrollMonitor
	 */
	set elements( elements:Array<View> ){
		this._elements = elements;
		this.visibleElements = [];
		this.update();
	}
	
	/**
	 * Iterates over all the rendered Elements and updates the visibleElements array
	 */
	public update(forceChangeEvent: boolean = false) {

		// Find all elements that cover at least half the viewport, or are fully within it:
		const viewport = this.getViewport().shift(+this.offsetTop, -this.offsetBottom);
		const currentElements = this._elements.filter(e => {
			const {top, bottom} = e.node.bounds();
			const element = new Interval(top, bottom);
			const visiblePartOfElement = element.intersectWith(viewport);
			return visiblePartOfElement.length >= Math.min(viewport.length / 2, element.length - 5);
		});
			
		// compare 
		this.compareElements( currentElements, forceChangeEvent );
	}
	
	private getViewport(): Interval {
		const top: number = Window.scrollInfo().top;
		const height = Window.viewport().height;
		return new Interval(top, top + height);
	}

	/**
	 * Compares the given current elements and check with the visibleElements which are still visible or not.
     */
	private compareElements( currentElements:Array<View>, forceChangeEvent: boolean = false ) {
		
		var hasChange = forceChangeEvent;
		
		// check if current element is new visible
		currentElements.forEach( ( element:View, index )=> {
			if( this.visibleElements.indexOf(element) == -1 ) {
				hasChange = true;
			}
		});
		
		// check if any of the old elements are not visible anymore.
		this.visibleElements.forEach( ( element, index )=> {
			if( currentElements.indexOf(element) == -1 ) {
				hasChange = true;
			}
		});
		
		// check if top element has changed
		hasChange = hasChange || currentElements[0] != this.visibleElements[0];
		
		// update the visible element
		this.visibleElements = currentElements;
			
		if( hasChange ) this.change.dispatch( currentElements );
	}

	public scrollToElementHash(){

		// no element hash, scroll to page top
		// added 'undefined' and '#' because IE11 has a problem with them
		if( window.location.hash == '' || typeof window.location.hash == 'undefined' || window.location.hash == '#' ){
			window.scrollTo( 0, 0 );
		} else {

			Node.one( window.location.hash ).native.scrollIntoView();
	
			// plus add scrolling for offset
			if( this.offsetTop != 0 ){
				window.scrollBy( 0, -this.offsetTop );
			}
		}
	}
}

export default ScrollMonitor;
