import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import LernBuch from'lb/LernBuch';
import { ListRenderer } from 'ln/list/ListRenderer';

import { mapper } from 'lb/LernBuch';
import { defaultsetup, chapter } from 'lb/setup/defaultsetup';
import setup from 'ln/setup/setup';
import { SetupConfig } from 'ln/setup/setup';
import CheckboxFilter from 'lnui/filter/CheckboxFilter';
import TermFilter from 'lnui/filter/TermFilter';
import Model from 'ln/model/Model';
import Lang from 'ln/lang/Lang';

import ModifiableList from 'ln/list/ModifiableList';

import Filter from './views/Filter';

import './templates/filters';
import './templates/elements';
import './templates/lernbuch';
import './templates/icons';


Template.context.setup = setup;
Template.context.imagePath = function( image:{ file_name:string }, preset:string = 'medium', ext:string = 'png' ){
	if( image) {
		return decodeURIComponent( setup.route( 'asset', { file_name:  image.file_name, preset:preset, ext:ext } ).url() );
	} else {
		return 'https://via.placeholder.com/233x150/F0F0F0/000000?text=' + Lang.translate( 'Bild fehlt' );
	}
}

export function init( config:SetupConfig ){
	
	mapper.toModel.add( 'App\\Asset', function( obj ) {
		return obj;
	});

	defaultsetup();
	Lang.add( config.data.lang );

	
	setup.init( config );

	var lb = new LernBuch( setup.data( 'book' ) );
	lb.scrollMonitor.offsetTop = 200;

	lb.render( Node.js( 'lernbuch' ) );

	lb.showSlug( setup.data( 'chapter' ) );

	Node.js('menu').click.add( (node)=> {
        Node.js('sidebar').toggleClass('-hide');
	});

	Node.js('home').click.add( (node) => {
		window.location.href = setup.route( 'overview' ).url()
	});
	
    lb.chapterChanged.add( () => {
        Node.js('sidebar').addClass('-hide');
    });

};



export function initOverview( config:SetupConfig ){
	
	var filterJobs = new Array();

	mapper.toModel.add( 'App\\Asset', function( obj ) {
		return obj;
	});

	defaultsetup();
	Lang.add( config.data.lang );


	Template.context.backend = function( slug:string ) {
		return decodeURIComponent( setup.route( 'backend', { book: slug } ).url() );
	}
	
	setup.init( config );

	var books = mapper.model( setup.data( 'books' ));



	var listRenderer = new ListRenderer<{}, ModifiableList<Model>>( Node.js('lernbuecher') );

	listRenderer.ioc.add( 'default', function( item ) {
		var node = Node.fromHTML( Template.render( 'lb.lernbuch-card', item ));
		node.click.add( function() {
			window.location.href = setup.route( 'chapter', { "book": item.slug, "chapter": item.chapters[0].slug, "uid": '' } ).url()
		});
		return { node: node }
	})
	
	listRenderer.source = new ModifiableList();
	listRenderer.source.originals.fill( books );

	var filter = new Filter( listRenderer.source );
	filter.render( Node.js('sidebar') );
	filter.registerOn( listRenderer.source );
	

	Node.js( 'close-sidebar' ).click.add( function( ) {
		Node.js( 'sidebar' ).addClass( '-hide' );
	}.bind( this ))

	Node.js( 'show-sidebar').click.add( function() {
		Node.js( 'sidebar' ).removeClass( '-hide' );
	}.bind( this ))

	Node.js( 'current-locale' ).click.add( function() {
		Node.js( 'locale-switcher' ).removeClass( '-hide' );
	})

};
