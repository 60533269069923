import Node from '../../core//node/Node';
import Model from '../../core//model/Model';
import Template from '../../core//template/TemplateManager';
import ModelFilter from './ModelFilter';

class CheckboxFilter extends ModelFilter {

    public checkboxTemplate: string;

    constructor( searchAttributes: string[] | string ) {
        super( searchAttributes );
        this.defaultTemplate = 'ui.filter-checkbox';
        this.checkboxTemplate = 'ui.single-checkbox';
    }

    init() {
        super.init();
    }

    fill( data: string[] ) {

        data.forEach( function ( item ) {
            var temp = Node.fromHTML( Template.render( this.checkboxTemplate, item ) );
            temp.one( 'input' ).change.add( this.change.dispatch.bind( this.change ) );
            this.node.js( 'list' ).append( temp );
        }, this );

        return this;
    }


    reset() {
        var selected = this.node.js( 'list' ).all( 'input[type=checkbox]:checked' );
        if ( selected.length == 0 ) return false;
        selected.forEach( function ( item ) {
            ( item.native as HTMLInputElement ).checked = false;
        } );
    }


    filter( models:Model[] ):Model[] {

        if ( !this.node.js( 'list' ) ) return models;

        var selected = this.node.js( 'list' ).all( 'input[type=checkbox]:checked' );
        if ( selected.length == 0 ) return models;

        return models.filter( ( model:Model ) => {
            return selected.some( ( item ) => {
                return this.filterIndexOf( item.value, model );
            });
        });
    }
}


export default CheckboxFilter;